<template>
    <v-container fluid>
        <v-form @submit.prevent="getFoods">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" @click="dialogFood=true" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('food_main_page') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn @click="dialogFood=true"  dark color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table item-key="id" ref="table" :headers="headers" :items="foodItems"
                              :options.sync="options" disable-pagination
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')"
                              class="sortable-table elevation-1"
                              hide-default-footer>
                    <template v-slot:item.sort>
                        <v-btn icon class="handle">
                            <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteFood(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="dialogFood"
            persistent
            max-width="700"
        >
            <ValidationObserver ref="addInvoice" v-slot="{ invalid, validated, passes, validate }">

                <v-card>
                    <v-card-title class="text-h5">
                        {{$t('food_creation')}}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="type_company" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="type_company" :disabled="loadingTypeCompanies"
                                              :items="filteredTypeCompanyItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              @change="resetTypeCompany"
                                              :label="$t('type_company')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6" v-if="type_company === 2">
                                <ValidationProvider ref="coffee_house" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="coffee_house" :disabled="loadingCoffeeHouses"
                                              :items="coffee_houseItems" :error-messages="errors"
                                              prepend-icon="mdi-coffee-maker" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('coffee_house')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6" v-if="type_company === 3">
                                <ValidationProvider ref="restaurant" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="restaurant" :disabled="loadingRestaurants"
                                              :items="restaurantItems" :error-messages="errors"
                                              prepend-icon="mdi-food-variant" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('restaurant')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text

                            @click="cancelDialogFood()"
                        >
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            class="white--text" color="indigo"
                            @click="addFood()"
                            :disabled="invalid || loading"
                            :loading="loading"
                        >
                            {{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Sortable from 'sortablejs'
    import {mapGetters} from "vuex"

    export default {
        name: "Foods",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                food: null,
                loading: false,
                sortBy: "sort",
                sortDir: false,
                options: {},
                foodItems: [],
                headers: [
                    {
                        text: "",
                        align: "left",
                        sortable: false,
                        value: "sort",
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: false,
                        value: "name"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                dialogFood: false,
                loadingTypeCompanies: false,
                type_company: null,
                type_companyItems: [],
                loadingTypeCompany: false,
                coffee_house: null,
                coffee_houseItems: [],
                loadingCoffeeHouses: false,
                restaurant: null,
                restaurantItems: [],
                loadingRestaurants: false,
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage']),
            filteredTypeCompanyItems() {
                return this.type_companyItems.filter(item => item.id === 2 || item.id === 3);
            },
        },
        mounted() {
            let table = this.$refs.table.$el.querySelector("tbody")
            Sortable.create(table, {
                handle: ".handle",
                onEnd: this.dragReorder
            })
            this.getTypeCompany()
            this.getCoffeeHouses()
            this.getRestaurants()
        },
        watch: {
            options: {
                handler() {
                    this.getFoods()
                },
                deep: false
            }
        },
        methods: {
            dragReorder({newIndex, oldIndex}) {
                const rowSelected = this.foodItems.splice(oldIndex, 1)[0]
                this.foodItems.splice(newIndex, 0, rowSelected)
                this.saveSortFood()
            },
            async saveSortFood() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.foodItems && this.foodItems.length > 0) {
                    for (let i in this.foodItems) {
                        if (this.foodItems[i].id !== 'undefined' && this.foodItems[i].id > 0) {
                            formData.append(`sort[${i}]`, this.foodItems[i].id)
                        }
                    }
                }
                await this.$http
                    .post(`admin/food/sort`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('food_sorting_updated'))
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('food_sorting_not_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getFoods() {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.food) {
                    params.food = this.food
                }
                await this.$http
                    .get("admin/food", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.foodItems = res.body.data
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_foods'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteFood(item) {
                if (confirm(this.$t('delete_food'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/food/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('food_has_been_deleted'))
                            this.getFoods()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('food_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getTypeCompany() {
                this.loadingTypeCompany = true
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/type_company`, {
                        params: params,
                    })
                    .then(res => {
                        this.type_companyItems = res.body.data
                    })
                    .catch(err => {
                        this.type_companyItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_transaction_type_companies'))
                    })
                    .finally(end => {
                        this.loadingTypeCompany = false
                    });
            },
            async getCoffeeHouses() {

                this.loadingCoffeeHouses = true
                let params = {}
                params.filter = 'all'
                await this.$http
                    .get("admin/coffee_house", {
                        params: params,
                    })
                    .then(res => {
                        this.coffee_houseItems = res.body.data
                    })
                    .catch(err => {
                        this.coffee_houseItems = []
                    })
                    .finally(end => {
                        this.loadingCoffeeHouses = false
                    })

            },
            async getRestaurants() {

                this.loadingRestaurants = true
                let params = {}
                params.filter = 'all';

                await this.$http
                    .get("admin/restaurant", {
                        params: params,
                    })
                    .then(res => {
                        this.restaurantItems = res.body.data
                    })
                    .catch(err => {
                        this.restaurantItems = []
                    })
                    .finally(end => {
                        this.loadingRestaurants = false
                    })

            },
            resetTypeCompany() {
                this.coffee_house = null
                this.restaurant = null
            },
            cancelDialogFood(){
                this.dialogFood = false;
                this.type_company = null;
                this.resetTypeCompany();
            },
            async addFood() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.coffee_house) {
                    formData.append('coffee_house', this.coffee_house)
                }
                if (this.restaurant) {
                    formData.append('restaurant', this.restaurant)
                }
                if (this.type_company) {
                    formData.append('type_company_id', this.type_company)
                }
                // Add
                await this.$http
                    .post('admin/food', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('food_has_been_added'))
                        this.cancelDialogFood();
                        this.getFoods();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('food_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            }
        }
    }
</script>
